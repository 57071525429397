import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-8eca49f8"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "d-flex align-items-center",
  style: {
    "margin-bottom": "10px"
  }
};
var _hoisted_2 = {
  class: "col-auto"
};
var _hoisted_3 = {
  class: "col-auto d-flex align-items-center"
};
var _hoisted_4 = {
  class: "waterfalls"
};
import { useRoom } from "@/composables/useRoom";
export default {
  __name: 'ConsumableRoomModal',
  emits: ["select-bed"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;

    var _useRoom = useRoom(),
        room = _useRoom.room,
        roomStatusName = _useRoom.roomStatusName,
        changeStatus = _useRoom.changeStatus;

    return function (_ctx, _cache) {
      var _component_MSelect = _resolveComponent("MSelect");

      var _component_BaseCheckbox = _resolveComponent("BaseCheckbox");

      var _component_RoomItem = _resolveComponent("RoomItem");

      var _component_LoadingContent = _resolveComponent("LoadingContent");

      var _component_NoData = _resolveComponent("NoData");

      var _directive_width = _resolveDirective("width");

      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_MSelect, {
        modelValue: _unref(room).roomStatus,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _unref(room).roomStatus = $event;
        }),
        options: _unref(room).roomStatusList,
        placeholder: "完成狀態"
      }, null, 8, ["modelValue", "options"])])), [[_directive_width, 175]]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_BaseCheckbox, {
        modelValue: _unref(room).showNullRoom,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _unref(room).showNullRoom = $event;
        }),
        style: {
          "margin-left": "20px"
        },
        text: "只顯示空床"
      }, null, 8, ["modelValue"])])]), _createVNode(_component_LoadingContent, {
        visible: _unref(room).ajaxDone,
        "spinner-absolute": ""
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(room).list, function (item) {
            return _openBlock(), _createBlock(_component_RoomItem, {
              key: item.id,
              data: item,
              status: _unref(roomStatusName)[item.room_status],
              "has-button": "",
              onSelectBed: _cache[2] || (_cache[2] = function ($event) {
                return _ctx.$emit('select-bed', $event);
              }),
              onChangeStatus: _unref(changeStatus)
            }, null, 8, ["data", "status", "onChangeStatus"]);
          }), 128))])];
        }),
        _: 1
      }, 8, ["visible"]), _unref(room).ajaxDone ? (_openBlock(), _createBlock(_component_NoData, {
        key: 0,
        "abs-center": "",
        variant: "logo",
        watch: "",
        "watch-data": _unref(room).list
      }, null, 8, ["watch-data"])) : _createCommentVNode("", true)]);
    };
  }
};